@import "@styles/variables";
.mainContainer {
  margin-left: auto;
  margin-right: auto;
  max-width: 100vw;
  padding-left: 0px;
  padding-right: 0px;

  &.hagglePage {
    padding-top: 100px;
  }
  @media (max-width: $screen-width-sm) {
    padding: 0;
    padding-top: 80px;
  }
  @media (max-width: $screen-width-xs) {
    padding-top: 90px;
  }
  &.hagglePage {
    @media (max-width: $screen-width-sm) {
      padding: 0;
      padding-top: 133px !important;
    }
  
  }    
  @media (max-width: 1920px) {
    padding-left: 160px;
    padding-right: 160px;
  }

  @media (max-width: 1600px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (max-width: $screen-width-sm) {
    padding-left: 0px;
  padding-right: 0px;
  }

}
.bannerContainer {
  background-color: #ecdfd5;
  &.hagglePage {
    padding-block: 36px;
  }
  @media (max-width: $screen-width-sm) {
    padding: 0 16px 10px;
  }
}
.promoImage {
  position: absolute;
  top: -110px;
  &.hagglePage {
  top: -128px;
  }
  display: block;
  &.hagglePage {
    right: 60%;
    transform: translateX(-15%);
    @media (min-width: $screen-width-md) and (max-width: $screen-width-lg) {
    right: 40%;
    transform: translateX(-50%);
    }
  }
  @media (max-width: $screen-width-md) {
    width: 143px;
    height: 130px;
    top: -90px;
    
    &.hagglePage {
    width: 264px !important;
    height: 176px !important;
    top: -120px;
    right: auto;
    transform: unset;
    span {
      width: inherit !important;
      height: inherit !important;
      top: -14px;
    }
  

}

}
&.hagglePage {

@media (min-width: 1024px) and (max-width: 1100px) {
  width: 394px !important;
  height: 291px !important;
  top: -100px;
  right: 41%;
  transform: translateX(-50%);
  span {
    width: inherit !important;
    height: inherit !important;
    top: -14px;
  }
}}
}

.imgSection {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin-left: 10%;
  position: relative;
  @media (max-width: $screen-width-md) {
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }
}
.promoDescriptionContainer {
  display: flex;
  flex-direction: column;
  margin-left: 40%;
  @media (max-width: $screen-width-lg) and (min-width: $screen-width-md) {
  margin-left: 45%;
  }
  @media (max-width: $screen-width-md) {
    justify-content: center;
    align-items: center;
    padding-bottom: 16px;
    margin-top: 20px;
    margin-left: 0;
  }
  @media (max-width: $screen-width-xs) {
    margin-top: 30px;
    margin-left: 0;
    padding-left: 16px;
    padding-right: 16px;
  }
}
.titleWrapper {
   max-width: 400px;
  @media (max-width: $screen-width-md) {
    padding-top: 20px;
  }
  @media (max-width: $screen-width-sm) {
    font-size: $fs-base;
    justify-content: center;
    text-align: center;
    padding-bottom: 10px;
    padding-top: 0;
  }
}
.promoDescription {
  @media (max-width: $screen-width-sm) {
    font-size: $fs-xs;
  }
}
.descBtnContainer{
  display: flex;
  @media (max-width: $screen-width-md) {
    flex-direction: column;
    align-items: center;
  }
}
.shopButtonWrapper {
  @media (max-width: $screen-width-sm) {
    justify-content: center;
  }
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;  
  border-radius: 0;
  width: 134px;
  height: 48px;
  @media (max-width: $screen-width-md) {
    min-height: 40px;
  }
  @media (max-width: $screen-width-sm) {
    width: 284px;
  }
}
