@import "@styles/variables";

.heroContainer {
  position: relative;
  .heroImage {
    position: relative;
    z-index: -1;
    width: 100%;
    object-fit: cover;
    transform: scaleX(1);
    min-height: 280px;
    @media (max-width: $screen-width-md) {
      transform: scaleX(-1);
    }
    @media (max-width: 576px) {
      transform: scaleX(1);
    }
  }
  .heroContentContainer {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 43%;
    width: 100%;
    @media (max-width: $screen-width-md) {
      padding-left: 5%;
    }
    @media (max-width: $screen-width-xs) {
      top: 35%;
    }
    .heading {
      color: white;
      max-width: 750px;
      p {
        margin-bottom:48px ;
      }
      span,
      u {
        text-decoration: none;
        text-transform: none;
        background-image: linear-gradient(transparent 84%, $tertiary-yellow 30%);
      }

      @media (max-width: $screen-width-md) {
        max-width: 280px;
      }
    }
    .linkBtn {
      height: 48px;
      width: 142px;
      font-weight: 700;
    }
  }
}
