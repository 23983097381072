@import "@styles/variables";

.heroContainer {
  position: relative;
  .heroImage {
    position: relative;
    z-index: -1;
    width: 100%;
    object-fit: cover;
    height: 550px;
    @media (max-width: $screen-width-xxl) {
      height: 450px;
    }
    @media (max-width: $screen-width-xl) {
      height: 460px;
    }
    @media (max-width: $screen-width-xs) {
      height: 400px;
    }
  }
  .heroContentContainer {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &:not(.moveHeroContentContainer) {
      padding-left: 5%;
      padding-right: 5%;
    }
    width: 100%;
    .heading {
      margin-bottom: 0;
      max-width: 900px;
      img {
        padding: 16px 12px;
        background-color: rgba(0, 0, 0, 0.5);
        @media (max-width: $screen-width-md) {
              padding: 0;
              height: 20px;
              background-color: unset;
              margin-bottom: 12px;
        }
      }
      p {
        margin-bottom: 35px;
      }
      u {
        text-decoration: none;
        background-image: linear-gradient(transparent 84%, $neutral-10 30%);
      }
    }
    .linkBtn {
      height: 48px;
      width: 142px;
      font-weight: 700;
    }
  }
  .moveHeroContentContainer {
    @media (max-width: $screen-width-md) {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 16px;
      img {
        padding-bottom: 20px;
      }
    }
    .heading {
      p {
        margin-bottom: 0;
        color: $white-color;
        font-size: $fs-xxl;
        font-weight: $bold-font-weight;
        @media (max-width: $screen-width-md) {
          font-size: $fs-h2;
        }
      }
    }
  }
}
