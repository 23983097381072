@import "@styles/variables";
.mainContainer {
  margin-left: auto;
  margin-right: auto;
  max-width: 100vw;
  padding-left: 16px;
  padding-right: 16px;

  
  @media (max-width: $screen-width-md) {
    background-color: $white-color !important;
    padding-left: 16px;
    padding-right: 16px;
  }

  @media (max-width: 1920px) {
    padding-left: 160px;
    padding-right: 160px;
  }

  @media (max-width: 1600px) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (max-width: $screen-width-sm) {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.cardSection {
  box-shadow: -20px 14px 54px 0px #e1d3cb80;
  padding-left: 0 !important;
  @media (max-width: $screen-width-md) {
    box-shadow: unset;
    .cardItem {
      padding: 28px 8px;
      .rightArrow {
        display: none;
      }
    }
  }
}
.moveTitle {
  .title {
    line-height: 44px;
    max-width: 700px;
  }
  .subtitle {
    line-height: 26px;
    max-width: 750px;
  }
  @media (max-width: $screen-width-md) {
    .title {
      font-size: $fs-h2;
      padding-top: 48px;
      padding-bottom: 16px;
      line-height: 38px;
    }
    .subtitle {
      font-size: $fs-h6;
      line-height: 22px;
      padding-bottom: 16px;
      line-height: 26px;
    }
  }
  p {
    margin-bottom: 0;
  }
  a {
    font-weight: $bold-font-weight;
    text-decoration: underline;
    text-decoration-color: $primary-color;
    text-underline-offset: 5px;
    &:hover {
      color: inherit;
    }
  }
}
.cardsContainer {
  padding: 70px 0;
  @media (max-width: $screen-width-md) {
    padding: 0;

    > div:last-child .cardItem {
      border: 0;
    }
  }
}

.cardItem {
  border: 1px solid $secondary-light;
  background-color: $white-color;
  display: block;

  &:hover {
    color: inherit;
    box-shadow: -20px 14px 54px rgba(225, 211, 203, 0.5);

    .cardLink {
      span {
        border-color: $white-color;
      }
    }
  }

  .rightArrow {
    display: none;
  }

  @media (max-width: $screen-width-md) {
    border: 0;
    border-bottom: 1px solid $secondary-light;
    padding: 28px 4px;
    display: flex;
    align-items: center;

    &:hover {
      border-bottom: 1px solid $secondary-light;
      box-shadow: none;
    }

    > div:first-child {
      padding: 0;
      padding-right: 24px;
      flex: 1;
    }

    .rightArrow {
      display: block;
    }
  }
}
.IconWrapper {
  display: none;
  @media (max-width: $screen-width-sm) {
    display: block;
    position: absolute;
    right: 27px;
    bottom: 80px;
  }
}
.cardLink {
  span {
    border-bottom: 2px solid $primary-color;
  }
}

.cardTitle {
  height: 80px;
  @media (max-width: $screen-width-lg) {
    font-size: $fs-h4;
    margin-bottom: 8px;
  }
  @media (max-width: $screen-width-md) {
    font-size: $fs-base;
    height: auto;
    margin-bottom: 20px;
  }
}

.cardDescription {
  height: 48px;
  @media (max-width: $screen-width-md) {
    height: auto;
  }
}

.moveCards {
  line-height: 22px;
  height: 100px;
  @media (max-width: $screen-width-md) {
    height: unset;
  }
}
.moveCardTitle {
  display: flex;
  align-items: center;
  @media (max-width: $screen-width-md) {
    font-size: 20px;
  }
}
