@import "@styles/variables";

.CardContainer {
  .featuredVendors {
    flex-wrap: wrap;
    overflow-x: unset;
    justify-content: space-between;

    @media (max-width: $screen-width-sm) {
      justify-content: space-evenly;
    }
  }
}

.CategoriesWithImage {
  background-color: $secondary-lighter;

  &.WelcomeBanner {
    .Categories {
      display: inline-flex;
      flex-wrap: nowrap;
      width: 100%;
      max-width: 100%;
      overflow-x: auto;
      justify-content: flex-start;

      &::-webkit-scrollbar {
        width: 8px;
        height: 12px;
      }

      &::-webkit-scrollbar-thumb {
        background: $primary-lighter;
        border-radius: 50px;
      }

      &::-webkit-scrollbar-track {
        background-color: $neutral-20;
        border-radius: 50px;
      }

      a:hover {
        color: initial;
      }

      .CategoryItem {
        margin-bottom: 20px;
      }
    }

    .CardContainer {
      padding-bottom: 20px;
    }
  }
}

.SectionTitle {
  margin-bottom: 70px;
  display: flex;
  align-items: center;

  a {
    font-size: $fs-sm;
    font-weight: 700;
    height: 21px;
    line-height: 1.125rem;
    vertical-align: middle;
    @media (max-width: $screen-width-sm) {
      font-size: $fs-xs;
    }
  }
}

.Categories {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  .CategoryItem {
    display: block;
    width: 190px;
  }

  .CategoryImage {
    border: none;
    border-radius: 50%;
    width: 190px;
    height: 190px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: $screen-width-md) {
      &:hover {
        box-shadow: 0 7px 18px rgba(0, 0, 0, 0.45);
      }
    }
  }
}

.businessContainer {
  .Categories {
    gap: 18px;
  }
}

.Categories {
  .CardItem {
    border: 2px solid $oxford-blue;
    border-radius: 130px;
    width: 198px;
    height: 68px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:hover {
      background-color: $primary-lighter;
      border-color: $primary-light;
    }
  }
}

.ShopByBrand {
  .Categories {
    .CategoryItem {
      margin-bottom: 16px;
    }

    a:hover {
      color: initial;
    }

    .BrandItem {
      .CardItem {
        @media (min-width: $screen-width-md) {
          &:hover {
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.45);
          }
        }
      }
    }
  }
}

@media (max-width: $screen-width-sm) {
  .CardContainer {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .SectionTitle {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 28px;
    justify-content: space-between;
  }

  .Categories {
    justify-content: space-around;
    max-width: 550px;
    margin: auto;

    .CategoryItem {
      width: 132px;
    }

    .CategoryImage {
      width: 132px;
      height: 132px;
    }

    .CardTitle {
      font-size: $fs-sm;
      line-height: 1rem;
    }

    .BrandItem {
      .CategoryImage {
        padding: 22px;
      }
    }
  }

  .ShopByBrand .Categories {
    max-width: 100%;
  }

  .businessContainer {
    .Categories {
      gap: 0;
      justify-content: space-between;

      .CardItem {
        width: 31%;
        margin-bottom: 16px;
      }
    }
  }
}

@media (max-width: $screen-width-xs) {
  .businessContainer {
    .Categories {
      .CardItem {
        width: 48%;
      }
    }
  }
}

@media (min-width: $screen-width-md) {
  .Categories {
    padding-left: 15px;
    margin-left: -15px;
  }
}
