//Colors

$discount-color: #b11010;
$red-color1: #f7443a;
//Primary Colors
$primary-color: #076ddc;
$primary-dark: #0655ad;
$primary-darker: #012d5f;
$primary-light: #b5d7fd;
$primary-lighter: #d5e6f9;

//Secondary Colors
$secondary-color: #e3c8b5;
$secondary-dark: #c08358;
$secondary-darker: #382315;
$secondary-light: #ecdfd5;
$secondary-lighter: #f1eae5;
$secondary-yellow: #ffc335;
$secondary-gray: #BFBFBF;
$secondary-blue: #2c8de8;

//Tertiary Colors
$tertiary-blue: #07dcb6;
$tertiary-pink: #f26767;
$tertiary-yellow: #fbbc05;
$tertiary-green: #7edc07;
$tertiary-beige: #e5b795;

//Black Colors
$white-color: #ffffff;
$neutral-10: #f8f8f8;
$neutral-20: #f2f2f2;
$neutral-30: #e7e7e7;
$neutral-40: #bcbcbc;
$neutral-50: #898989;
$neutral-60: #888888;
$neutral-70: #333333;
$black-color: #000000;

//Other Colors
$success: $tertiary-green; //success
$error: $tertiary-pink; //error
$oxford-blue: #02254a;
$jeans-blue: #52a2f9;
$footer-bg-right: #fbfbfb;
$transparent: transparent;
$collapse-active: #d5e6f980;
$input-border:#404b6033;

//Font Type
$primary-font-family: 'Inter', sans-serif;

//Font Sizes

//base font size
$fs-base: 16px;

/* larger than heading font sizes */
$fs-xxxl: 80px;
$fs-xxl: 64px;
$fs-xl: 56px;
$fs-lg: 40px;

/* heading font sizes */
$fs-h1: 36px;
$fs-h2: 32px;
$fs-h3: 28px;
$fs-h4: 24px;
$fs-h5: 20px;
$fs-h6: 18px;

/* smaller than heading font sizes */
$fs-sm: 14px;
$fs-xs: 12px;
$fs-xxs: 10px;

// Breakpoints
$screen-width-mobile-sm: 320px;
$screen-width-mobile-md: 375px;
$screen-width-mobile-lg: 425px;
$screen-width-xs: 576px;
$screen-width-sm: 768px;
$screen-width-md: 992px;
$screen-width-lg: 1200px;
$screen-width-xl: 1440px;
$screen-width-xxl: 1600px;

// Container
$max-width-container: 1600px;

// Font weight
$normal-font-weight: 300;
$regular-font-weight: 400;
$medium-font-weight: 500;
$semi-font-weight: 600;
$bold-font-weight: 700;
$bolder-font-weight: 800;
