@import "@styles/variables";
.noResult {
  text-align: center;
  background-color: $secondary-lighter;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $screen-width-sm) {
    flex-direction: column;
    padding: 24px 16px;
  }

  .title {
    font-size: 32px;
    font-weight: 800;
    margin: 0 24px 0 12px;

    @media (max-width: $screen-width-sm) {
      margin-top: 12px;
      margin-bottom: 16px;
      font-size: 24px;
    }
  }

  .requestItBtn {
    font-weight: $bold-font-weight;
    width: 160px;
    height: 48px;
    @media (max-width: $screen-width-sm) {
      width: 120px;
      height: 40px;
      font-size: 12px;
    }
  }
}
